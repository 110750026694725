/* Fonts */
$nunitoStack: 'Nunito Sans', Helvetica, Arial, sans-serif;

/* Colors */
$daytimeTopColorHex: #db752a;
$daytimeMiddleColorHex: #cb5538;
$daytimeBottomColorHex: #883468;
$nighttimeTopColorHex: #793682;
$nighttimeBottomColorHex: #202175;
$primary: $nighttimeBottomColorHex;
$nighttimeBottomColorHex80: rgba(32, 33, 117, 0.8);
$nighttimeBottomColorHex50: rgba(32, 33, 117, 0.5);
$white: #fff;
$black: #000;
$black15: rgba(0, 0, 0, 0.15);
$black50: rgba(0, 0, 0, 0.5);
$black65: rgba(0, 0, 0, 0.65);
$black80: rgba(0, 0, 0, 0.8);
$greyLight: #e1e1e1;
$greyMediumLight: #b1b1b1;
$greyMedium: #757575;
$grey: #4a4a4a;
$grey10: rgba(74, 74, 74, 0.1);
$grey50: rgba(74, 74, 74, 0.5);
$greyMediumLight40: rgba(177, 177, 177, 0.4);
$white30: rgba(255, 255, 255, 0.3);
$white50: rgba(255, 255, 255, 0.5);
$white67: rgba(255, 255, 255, 0.67);
$white80: rgba(255, 255, 255, 0.8);
$red: #ff0000;

$error: $red;
$error-a11y: #ff8b8b;
$link: $nighttimeBottomColorHex;
$noData: #0b6c82;
$onMainBackground: $white;
$onMainBackground50: $white50;
$onMainBackground80: $white80;
$mainBackground: $nighttimeBottomColorHex;
$onWidgetBackground: $nighttimeBottomColorHex;
$widgetBackground: $white;

$upTrend: #39b54a;
$downTrend: $red;
$flatTrend: #0b6c82;

/* Media Query Sizes */
$mq-min: 320px;
$mq-mxl: 480px;
$mq-sml: 600px;
$mq-med: 768px;
$mq-md2: 960px;
$mq-lrg: 1024px;
$mq-xlg: 1280px;
$mq-2xl: 1366px;
$mq-3xl: 1680px;
$mq-4xl: 1920px;
