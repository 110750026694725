@import 'variables';
@import 'mixins';

html,
body {
  padding: 0;
  margin: 0;
  min-height: 100vh;
  width: 100vw;
  max-width: 100%;
  min-width: $mq-min;
}

html {
  overflow-y: scroll;
  line-height: 1.3;

  @media screen and (min-width: $mq-min) {
    overflow-x: hidden;
  }
}

@media screen {
  .no-scroll {
    position: fixed;

    .top-header {
      position: absolute;
      top: 0;
    }
  }
}

body {
  font-family: $nunitoStack;
  color: $grey;
  background-color: $white;
  border-radius: 0;
  background-repeat: no-repeat;
  background-size: cover;
}

a {
  text-decoration: none;
  color: $grey;

  &:hover {
    text-decoration: underline;
  }
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
  margin-block-start: 0;
  margin-block-end: 0;
}

b,
strong {
  font-weight: bold;
}

svg {
  color: $grey;
  fill: $grey;
}

@media screen {
  .hidden-screen {
    /* Hide from visible screen (often used for things that should only be visible for printing) */
    display: none !important;
  }
}

select {
  display: inline-block;
  @include body-font(16, normal);
  font-family: sans-serif;
  line-height: 1.3;
  padding: 3px 16px 3px 10px;
  width: 100%;
  max-width: 100%; /* useful when width is set to anything other than 100% */
  margin: 0;
  border: 1px solid $white50;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  background-color: $white50;
  background-image: url('data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22%23007CB2%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E');
  background-repeat: no-repeat;
  /* arrow icon position */
  background-position: right 0 top 50%;
  /* icon size */
  background-size: 20px auto;
}
/* Hide arrow icon in IE browsers */
select::-ms-expand {
  display: none;
}

/* Focus style */
select:focus {
  outline: none;
}

/* Set options to normal weight */
select option {
  font-weight: normal;
}

/* fix placeholder contrast compared to sanitizer */
::placeholder {
  color: var(--onBackground67, $white67);
  opacity: 1;
}

/* Support for rtl text, explicit support for Arabic and Hebrew */
*[dir='rtl'] select,
:root:lang(ar) select,
:root:lang(iw) select {
  background-position: left 0 top 50%;
  padding: 6px 10px 6px 16px;
}

/* Disabled styles */
select:disabled,
select[aria-disabled='true'] {
  color: $grey;
  background-image: url('data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22graytext%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E');
}

select:disabled:hover,
select[aria-disabled='true'] {
  border-color: $greyLightMedium;
}

/* Accessibility (a11y) styles */
.sr-only,
.visually-hidden,
.visually-hidden-allow-focus:not(:focus):not(:active) {
  clip: rect(1px, 1px, 1px, 1px);
  clip-path: inset(50%);
  height: 1px;
  width: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
}

/* Button styles */
.button {
  display: block;
  align-self: center;
  height: 44px;
  line-height: 44px;
  padding: 0 14px;
  text-align: center;
  text-decoration: none;
  cursor: pointer;
  border-width: 0;
  @include body-font(16, bold);
  color: $grey;
  background-color: $white;
  @include no-text-wrap();

  &:hover,
  &:focus {
    background-color: $white80;
    text-decoration: none;
  }

  &[disabled],
  &.disabled {
    background-color: $white30;
    color: $white50;
    cursor: not-allowed;
  }
}

.button-reverse {
  background-color: $grey;
  color: $white;

  &:hover,
  &:focus {
    background-color: $grey70;
    text-decoration: none;
  }

  &[disabled],
  &.disabled {
    background-color: $grey30;
    color: $grey80;
    border: 1px solid transparent;
    cursor: not-allowed;
  }
}

.button-secondary {
  color: $white;
  background-color: transparent;
  border: 1px solid $white;

  &:hover,
  &:focus {
    background-color: $grey80;
    color: $grey;
    border: 1px solid transparent;
  }

  &[disabled],
  &.disabled {
    background-color: $grey30;
    color: $white50;
    border: 1px solid transparent;
    cursor: not-allowed;
  }
}

.button-secondary-reverse {
  color: $grey;
  background-color: transparent;
  border: 1px solid $grey;

  &:hover,
  &:focus {
    background-color: $grey70;
    color: $white;
    border: 1px solid transparent;
    text-decoration: none;
  }

  &[disabled],
  &.disabled {
    background-color: $grey50;
    color: $white50;
    border: 1px solid $grey;
    cursor: not-allowed;
  }
}

.button-opaque-border {
  background-color: $white;
  color: $grey;
  border: 1px solid $grey;

  &:hover,
  &:focus {
    background-color: $grey70;
    color: $white;
    border: 1px solid transparent;
    text-decoration: none;
  }

  &[disabled],
  &.disabled {
    background-color: $grey50;
    color: $white80;
    border: 1px solid $grey;
    cursor: not-allowed;
  }
}

.button-pill {
  border-radius: 35px;
}

.button-transparent-black {
  background-color: $black40;
  color: $white;

  &:hover {
    background-color: $black70;
  }

  svg {
    color: $white;
    fill: $white;
  }
}

.button-w-icon {
  display: flex;
  justify-content: center;
  align-items: center;

  .button-icon,
  svg-icon,
  svg,
  img {
    position: relative;
    top: -1px;
    display: inline-block;
    width: 15px;
    margin-left: 0;
    margin-right: 10px;

    &.button-icon-after {
      margin-left: 10px;
      margin-right: 0;
    }
  }

  &.icon-after {
    .button-icon,
    svg-icon,
    svg,
    img {
      margin-left: 10px;
      margin-right: 0;
    }
  }

  svg,
  img {
    height: 15px;
  }
}

/* Width Helpers */
.w10 {
  width: 10%;
}
.w20 {
  width: 20%;
}
.w25 {
  width: 25%;
}
.w30 {
  width: 30%;
}
.w40 {
  width: 40%;
}
.w50 {
  width: 50%;
}
.w60 {
  width: 60%;
}
.w70 {
  width: 70%;
}
.w75 {
  width: 75%;
}
.w80 {
  width: 80%;
}
.w87 {
  width: 87%;
}
.w90 {
  width: 90%;
}
.w92 {
  width: 92%;
}
.w95 {
  width: 95%;
}
.w100 {
  width: 100%;
}

/* Opacity Helpers */
.o10 {
  opacity: 10%;
}
.o15 {
  opacity: 15%;
}
.o20 {
  opacity: 20%;
}
.o25 {
  opacity: 25%;
}
.o30 {
  opacity: 30%;
}
.o40 {
  opacity: 40%;
}
.o50 {
  opacity: 50%;
}
.o60 {
  opacity: 60%;
}
.o70 {
  opacity: 70%;
}
.o75 {
  opacity: 75%;
}
.o80 {
  opacity: 80%;
}
.o90 {
  opacity: 90%;
}
.o100 {
  opacity: 100%;
}

input:focus {
  @include focus-shadow(5px, 4px);
}

button {
  border: none;
}

button:focus {
  @include focus-shadow(5px, 4px);
}

button[disabled] {
  cursor: not-allowed;
}

a:focus,
textarea:focus {
  @include focus-shadow;
}

*:focus:not(:focus-visible) {
  @include disable-focus-shadow();
}

*:focus-visible {
  @include focus-shadow;
}
