/* Global Variables */

/**
 NOTE: See Mixins for font mixins
 */
$nunitoStack: 'Nunito Sans', Helvetica, Arial, sans-serif;

/* Media Query Sizes */
$mq-min: 320px;
$mq-mn2: 375px; // Width of iPhone SE 2022
$mq-mxl: 480px;
$mq-sml: 600px;
$mq-smx: 640px;
$mq-med: 768px;
$mq-md2: 960px;
$mq-lrg: 1024px;
$mq-xlg: 1280px;
$mq-2xl: 1366px;
$mq-3xl: 1680px;
$mq-4xl: 1920px;

/* Height Media Query Sizes */
$mqh-min: 667px; // Height of iPhone SE 2022; needed for modals
$mqh-sml: 768px;
$mqh-med: 1024px;
$mqh-lrg: 1280px;

/* Base colors */
$black: #000;
$black04: rgba(0, 0, 0, 0.04);
$black05: rgba(0, 0, 0, 0.05);
$black10: rgba(0, 0, 0, 0.1);
$black15: rgba(0, 0, 0, 0.15);
$black20: rgba(0, 0, 0, 0.2);
$black30: rgba(0, 0, 0, 0.3);
$black40: rgba(0, 0, 0, 0.4);
$black50: rgba(0, 0, 0, 0.5);
$black60: rgba(0, 0, 0, 0.6);
$black67: rgba(0, 0, 0, 0.67);
$black70: rgba(0, 0, 0, 0.7);
$black80: rgba(0, 0, 0, 0.8);
$black90: rgba(0, 0, 0, 0.9);
$black95: rgba(0, 0, 0, 0.95);
$greySuperLight: #fafafa;
$greyLight: #e1e1e1;
$greyLightMedium: #aaa;
$greyMedium: #757575;
$greyMedium50: rgba(117, 117, 117, 0.5);
$grey: #4f4f4f;
$grey10: rgba(79, 79, 79, 0.1);
$grey30: rgba(79, 79, 79, 0.3);
$grey50: rgba(79, 79, 79, 0.5);
$grey70: rgba(79, 79, 79, 0.7);
$grey80: rgba(79, 79, 79, 0.8);
$white: #fff;
$white04: rgba(255, 255, 255, 0.04);
$white05: rgba(255, 255, 255, 0.05);
$white10: rgba(255, 255, 255, 0.1);
$white15: rgba(255, 255, 255, 0.15);
$white20: rgba(255, 255, 255, 0.2);
$white30: rgba(255, 255, 255, 0.3);
$white40: rgba(255, 255, 255, 0.4);
$white50: rgba(255, 255, 255, 0.5);
$white60: rgba(255, 255, 255, 0.6);
$white67: rgba(255, 255, 255, 0.67);
$white70: rgba(255, 255, 255, 0.7);
$white80: rgba(255, 255, 255, 0.8);
$white90: rgba(255, 255, 255, 0.9);
$white95: rgba(255, 255, 255, 0.95);
$red: #ff0000;
$blueMed: #0b6c82;
$greyBorder: 1px solid rgba(79, 79, 79, 0.3);
$trending-red: #c40303;
$trending-green: #34aa05;
$transparent: transparent;

// hard coded CSS custom properties for better browser support

:root {
  --grey: #{$grey};
  --greyMedium: #{$greyMedium};
  --grey50: #{$grey50};
  --sab: env(safe-area-inset-bottom);
}
