@use 'sass:list';

$focus-color: rgb(255, 216, 143);
$focus-color-on-white: rgb(201, 135, 13);

@mixin heading-font($size: 32, $weight: bold) {
  font-weight: $weight;

  $valid-sizes: 42, 36, 32, 28, 26, 24, 22, 20, 18, 16;

  @if list.index($valid-sizes, $size) {
    font-size: #{$size}px;
  } @else {
    @error '#{$size} is not a valid size. Expected one of #{$valid-sizes}.';
  }
}

@mixin body-font($type: normal, $weight: normal) {
  font-weight: $weight;

  $valid-types: extrasmall, small, normal, medium, large;
  $type-sizes: 12, 14, 16, 18, 22;

  @if list.index($valid-types, $type) {
    font-size: #{list.nth($type-sizes, list.index($valid-types, $type))}px;
  } @else if type_of($type) == number {
    font-size: #{$type}px;
  } @else {
    @error '#{$type} is not a valid size. Expected one of #{$valid-types} or a number.';
  }
}

@mixin form-label($size: normal, $weight: bold) {
  @include body-font(normal, bold);
}

@mixin focus-shadow($blur-radius: 5px, $spread-radius: 2px, $color: $focus-color, $offset-x: 0, $offset-y: 0) {
  outline: none;
  box-shadow: $offset-x $offset-y $blur-radius $spread-radius $color;
}

@mixin focus-shadow-on-white(
  $blur-radius: 5px,
  $spread-radius: 2px,
  $color: $focus-color-on-white,
  $offset-x: 0,
  $offset-y: 0
) {
  outline: none;
  box-shadow: $offset-x $offset-y $blur-radius $spread-radius $color;
}

@mixin focus-shadow-inner($color: $focus-color) {
  background: $color;
}

// Adding !important here to remove individual focus styles
@mixin disable-focus-shadow() {
  outline: none !important;
  box-shadow: none;
}

// Applying this mixin will force the text to be one line and truncated
// with ellipsis (unless other text-overflow option is passed)
// NOTE: This does apply overflow: hidden which may have undesired consequences if overflow is expected
// NOTE: May require a width / max-width setting to take affect
@mixin no-text-wrap($overflow-char: ellipsis) {
  text-wrap: nowrap;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: $overflow-char;
}

// Applying this mixin will force the text to be one line and truncated with ellipsis
// NOTE: This does apply overflow: hidden which may have undesired consequences if overflow is expected
// NOTE: This changes the display property which may have undesired consequences
@mixin line-clamp($lines: 3, $overflow-char: ellipsis, $orient: vertical) {
  display: -webkit-box;
  -webkit-box-orient: $orient;
  -webkit-line-clamp: $lines;
  overflow: hidden;
}
