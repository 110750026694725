/* You can add global styles to this file, and also import other style files */
@import '~normalize.css/normalize.css';
@import '~sanitize.css/sanitize.css';
@import '~sanitize.css/forms.css';
@import 'shared-assets/src/assets/styles/global';
@import 'shared-assets-insightful/src/assets/styles/theme';
@import 'shared-assets-insightful/src/assets/styles/mixins';
@import 'shared-assets-insightful/src/assets/styles/global';
@import '~tippy.js/dist/tippy.css';
@import '~tippy.js/animations/scale.css';
@import 'shared-assets-insightful/src/assets/styles/tooltips';
