@import 'shared-assets/src/assets/styles/mixins';
@import 'shared-assets/src/assets/styles/variables';
@import 'shared-assets-insightful/src/assets/styles/theme';

html,
body {
  padding: 0;
  margin: 0;
  min-height: 100vh;
  width: 100vw;
  max-width: 100%;
  min-width: $mq-min;
}

html {
  overflow-y: scroll;
  line-height: 1.3;
}

body {
  font-family: $nunitoStack;
  color: $nighttimeBottomColorHex;
  background-color: $mainBackground;
}

a {
  text-decoration: none;
  color: $link;

  &:hover {
    text-decoration: underline;
  }
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
  margin-block-start: 0;
  margin-block-end: 0;
}

select {
  display: inline-block;
  font-size: 16px;
  font-family: sans-serif;
  font-weight: 400;
  color: $onWidgetBackground;
  line-height: 1.3;
  padding: 3px 16px 3px 10px;
  width: 100%;
  max-width: 100%; /* useful when width is set to anything other than 100% */
  margin: 0;
  border: 1px solid $onMainBackground;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  background-color: $onMainBackground;
  background-image: url('data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22%23007CB2%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E');
  background-repeat: no-repeat;
  /* arrow icon position */
  background-position: right 0 top 50%;
  /* icon size */
  background-size: 20px auto;
}
/* Hide arrow icon in IE browsers */
select::-ms-expand {
  display: none;
}

/* Focus style */
select:focus {
  outline: none;
}

/* Set options to normal weight */
select option {
  font-weight: normal;
}

/* fix placeholder contrast compared to sanitizer */
::placeholder {
  color: $white67;
  opacity: 1;
}

/* Support for rtl text, explicit support for Arabic and Hebrew */
*[dir='rtl'] select,
:root:lang(ar) select,
:root:lang(iw) select {
  background-position: left 0 top 50%;
  padding: 6px 10px 6px 16px;
}

/* Disabled styles */
select:disabled,
select[aria-disabled='true'] {
  color: $grey;
  background-image: url('data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22graytext%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E');
}

select:disabled:hover,
select[aria-disabled='true'] {
  border-color: #aaa;
}

.hidden,
[hidden] {
  display: none;
}

.sr-only,
.visually-hidden,
.visually-hidden-allow-focus:not(:focus):not(:active) {
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}

/* Width Helpers */
.w10 {
  width: 10%;
}
.w20 {
  width: 20%;
}
.w25 {
  width: 25%;
}
.w30 {
  width: 30%;
}
.w40 {
  width: 40%;
}
.w50 {
  width: 50%;
}
.w60 {
  width: 60%;
}
.w70 {
  width: 70%;
}
.w75 {
  width: 75%;
}
.w80 {
  width: 80%;
}
.w90 {
  width: 90%;
}
.w100 {
  width: 100%;
}

/* Opacity Helpers */
.o10 {
  opacity: 10%;
}
.o20 {
  opacity: 20%;
}
.o25 {
  opacity: 25%;
}
.o30 {
  opacity: 30%;
}
.o40 {
  opacity: 40%;
}
.o50 {
  opacity: 50%;
}
.o60 {
  opacity: 60%;
}
.o70 {
  opacity: 70%;
}
.o75 {
  opacity: 75%;
}
.o80 {
  opacity: 80%;
}
.o90 {
  opacity: 90%;
}
.o100 {
  opacity: 100%;
}

div.tippy-info-triggered.info-icon svg {
  fill: $greyMedium;
}

div.tippy-info-clicked.info-icon svg {
  fill: $nighttimeBottomColorHex;
}
